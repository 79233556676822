import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Parablack,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="65px" height="65px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify={"space-between"} align={"top"} wrap={true}>
            {/* <Row justify="center" align="top"> */}
            {/* <Col lg={10} md={10} sm={12} xs={12}> */}
            <Col xs={24} sm={20} md={16} lg={12} xl={8}>
              <Language>{t("Get In Touch")}</Language>
              {/* <Large to="/">{t("(905) 574-2222")}</Large> */}

              {/* <a href="tel:9055742222" target="_blank" rel="noreferrer">
                <Para>{t("(905) 574-2222")}</Para>
              </a>
              <br></br> */}

              <a
                href="mailto:george@gtapong.com"
                target="_blank"
                rel="noreferrer"
              >
                <Para>{t("george@gtapong.com")}</Para>
              </a>
              <br></br>

              <a
                href="https://gtapong.com/stripe"
                target="_blank"
                rel="noreferrer"
              >
                <Para>{t(`Manage Membership`)}</Para>
              </a>
              <br></br>

              <a
                href="https://gtapong.com/membership-registration"
                target="_blank"
                rel="noreferrer"
              >
                <Para>{t(`Add Your Profile`)}</Para>
              </a>
              <br></br>

              <a
                href="https://gtapong.com/requests"
                target="_blank"
                rel="noreferrer"
              >
                <Para>{t(`Requests & Suggestions`)}</Para>
              </a>
              <br></br>

              {/* <Parablack>Mon-Thurs: 8am-5:30pm</Parablack>
              <Parablack>Fri: 8am-3pm</Parablack>
              <Parablack>Sat: 8am-2pm</Parablack> */}
            </Col>
            {/* <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("All On 4")}</Title>
              <Large to="https://www.stonehilldental.ca/dental-services/cosmetic-dentistry/teeth-in-a-day/#contact-us" left="true">
                {t("Our Comments")}
              </Large>
              <Large left="true" to="/">
                {t("Medical Study")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large left="true" to="/">
                {t("Dental Implants")}
              </Large>
              <Large left="true" to="/">
                {t("All On 4 Video")}
              </Large>
            </Col> */}
            {/* <Col xs={24} sm={20} md={16} lg={12} xl={8}>
              <Empty />
              <Language>{t("Address")}</Language>
              <Parablack>1314 Upper Wentworth St</Parablack>
              <Parablack>Hamilton, ON L9A 4W2</Parablack>
              <Parablack>Canada</Parablack>
              <br></br>
              <a href="https://goo.gl/maps/SHW3hpQ5vUdyxHvM9" target="_blank">
                <Para>{t(`View on Google Maps`)}</Para>
              </a>
            </Col> */}

            <Col xs={24} sm={20} md={16} lg={12} xl={8}>
              <Title>{t("Some of Our Sweet Setups!")}</Title>

              <a
                href="https://gtapong.com/downtown-toronto"
                target="_blank"
                rel="noreferrer"
              >
                <Para>{t("Downtown Toronto")}</Para>
              </a>
              <br></br>

              <a
                href="https://gtapong.com/oakville"
                target="_blank"
                rel="noreferrer"
              >
                <Para>{t("Oakville")}</Para>
              </a>
              <br></br>

              <a
                href="https://gtapong.com/shelburne-pong"
                target="_blank"
                rel="noreferrer"
              >
                <Para>{t(`Shelburne`)}</Para>
              </a>
              <br></br>

              <a
                href="https://gtapong.com/tottenham"
                target="_blank"
                rel="noreferrer"
              >
                <Para>{t(`Tottenham`)}</Para>
              </a>
              <br></br>
              {/* <Large left="true" to="/">
                {t("About")}
              </Large>
              <Large left="true" to="/">
                {t("Blog")}
              </Large>
              <Large left="true" to="/">
                {t("Press")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large> */}
            </Col>
            {/* </Row> */}
          </Row>
          {/* <Row justify="space-between"> */}

          {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col> */}
          {/* </Row> */}
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "1rem" }}
          >
            
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="4.png"
                  aria-label="homepage"
                  width="269px"
                  height="69px"
                />
              </LogoContainer>
            </NavLink>

            
            
              {/* <SocialLink
                href="https://github.com/Adrinlol/create-react-app-adrinlol"
                src="github.svg"
              /> */}
              {/* <SocialLink
                href="https://twitter.com/gtapongclub"
                src="twitter2.svg"
              /> */}
              <SocialLink
                href="https://www.instagram.com/gtapong_club/"
                src="instagram.svg"
              />

              <SocialLink
                href="https://join.slack.com/t/gtapong/shared_invite/zt-1tdl1k3t1-QFhRRkEvknKppHhYkamkNA"
                src="slack.svg"
              />

              <SocialLink
                href="https://www.facebook.com/gtapong"
                src="facebook2.svg"
              />

              <SocialLink
                href="https://www.linkedin.com/company/gta-pong/"
                src="linkedin2.svg"
              />

              {/* <a href="https://www.buymeacoffee.com/adrinlol">
                <img
                  src="https://img.buymeacoffee.com/button-api/?text=Buy me a pizza&emoji=🍕&slug=adrinlol&button_colour=FF5F5F&font_colour=ffffff&font_family=Lato&outline_colour=000000&coffee_colour=FFDD00"
                  alt="Buy me a pizza"
                />
              </a> */}
              
            
            
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
